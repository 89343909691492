.geo-root, .leaflet-container {
  max-width: 100%;
  width: 100%;
  height: 100% !important;
  //background-color: rgba(255, 255, 255, 1);
  background: #FAFAFA;
  overflow: visible;
}

.leaflet-tooltip {
  font-size: 11px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 1);
  padding: 4px 8px;
  transform-origin: center;

  &.region-info-city-tooltip {
    font-size: 11px;
    opacity: 0;
    transition: all 0.3s ease-out;

    &.selected {
      background: #FFF700;
      border-color: #777
    }

    &.active {
      opacity: 1 !important;
    }
  }

  &.leaflet-tooltip-right:before {
    //border-right-color: rgba(255,255,255,0.85);
    border-right-color: transparent;
  }

  &.leaflet-tooltip-left:before {
    //border-right-color: rgba(255,255,255,0.85);
    border-left-color: transparent;
  }

  &.leaflet-tooltip-top:before {
    //border-right-color: rgba(255,255,255,0.85);
    border-top-color: transparent;
  }

  &.leaflet-tooltip-bottom:before {
    //border-right-color: rgba(255,255,255,0.85);
    border-bottom-color: transparent;
  }
}

.landing-tooltip {
  padding: 12px 16px;
  max-width: 325px;

  .popup-link {
    text-decoration: underline;
    color: #096DD9;
    padding: 5px 0;
    user-select: none;
  }

  > div:first-child > div:first-child {
    flex: 1;
  }

  img {
    //height: 102px;
    border-radius: 3px;
    //margin-left: 16px;
  }
}

.test-result {
  margin-left: 5px;
}

.greeting-text {
  overflow: visible;
  max-width: 455px;
  z-index: 3;
  padding: 40px 10px 40px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;


  .greetings {
    font-weight: 700;
    font-size: 54px;
    line-height: 60px;
    white-space: nowrap;
  }

  .supporting-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding-top: 24px;
  }

  .buttons {
    padding-top: 32px;
    display: flex;

    .test-button {
      background: #096DD9;
      border-radius: 30px;
      color: #FFFFFF;
      //display: flex;
      margin-right: 16px;
      border: 0;
      font-style: normal;
      font-weight: 550;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .explore-button {
      background: #FFF700;
      border-radius: 30px;
      //display: flex;
      //margin-left: 16px;
      border: 0;
      font-style: normal;
      font-weight: 550;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 20px;
      min-width: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .country-info {
    justify-content: flex-start;

    > div {
      &:first-child {
        flex: 0 0 200px;
      }

      &:nth-child(2) {
        flex: 0 0 400px;
      }
    }

    .country-info-header {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      padding-top: 16px;

      img {
        //filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.4));
        user-select: none;
      }
    }

    .country-info-value {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      color: #262626;
      padding-top: 4px;

      .country-info-value-comment {
        font-size: 12px;
        color: #8C8C8C;
        margin-left: 6px;
      }
    }
  }
}

.column-header {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #096DD9;
}

.geo-root {
  display: flex;
  flex-direction: row;
  transition: all 0.7s;

  .info.icon {
    margin-left: 0.357rem;
  }

  .legend-info-sign {
    position: absolute;
    right: 0.142rem;
    top: 0.285rem;
  }

  .left-panel-wrapper {
    transition: all 0.7s;
    flex: 20.928rem 0 0;
    z-index: 3;

    &.menu-hidden {
      flex: 0 0 0;

      .header-menu-column-settings {
        transform: translateX(-20.928rem);
      }

      .header-item {
        &.active {
          border-right: 1px solid rgba(34, 36, 38, .15);
        }
      }
    }
  }

  .landing-map {
    path {
      cursor: default !important;
    }
  }

  .map-wrapper {
    //font-family: Mark, Fira Sans, Lato, sans-serif;;
    flex: 1;
    //border-bottom-right-radius: 27px;

    .leaflet-overlay-pane svg {
      //filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
    }

    .ui.active.loader {
      background-color: rgba(9, 9, 9, 1);
      padding: 3.071rem 0.357rem 0.357rem 0.428rem;
      border-radius: 5px;
      font-weight: 900;

      &:before, &:after {
        margin-top: 0.357rem;
      }
    }
  }

  .map-wrapper {
    //z-index: 2;
    position: relative;
  }
}

.ant-modal-root {
  .ant-modal-content, .ant-modal-header {
    border-radius: 27px;
  }

  .ant-modal-header {
    background: transparent;
    border: 0;

    .ant-modal-title {
      font-style: normal;
      font-weight: 550;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .region-info-popup {
    .ant-modal-header {
      background: transparent;
      border: 0;
      padding: 24px 32px 16px 24px;
      display: flex;
      flex-direction: row-reverse;


      > .ant-modal-title {
        width: 509px;
      }
    }

    .ant-modal-body {
      padding: 0 0 32px 32px;
    }

    .ant-tabs-content-holder {
      display: flex;
    }
  }
}

.ui.popup {
  position: absolute;
  top: 0;
  right: 0;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  z-index: 1900;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: #fff;
  padding: .833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: .28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.ui.bottom.popup:before, .ui.left.center.popup:before, .ui.right.center.popup:before, .ui.top.popup:before {
  background: #fff;
}

.ui.popup {
  background: rgba(0, 0, 0, 0.75) !important;
  border-radius: 2px;
  color: #FFFFFF !important;
  pointer-events: none !important;

  &.right:before {
    width: 9px !important;
    height: 9px !important;
    background: rgba(0, 0, 0, 0.75) !important;
    border: 1px solid #d4d4d5;
    transform: rotate(45deg) !important;
    FONT-WEIGHT: 100;
    left: -5px !important;
    box-shadow: none !important;
    pointer-events: none !important;
  }


  &.left:before {
    width: 0 !important;
    height: 0 !important;
    background: transparent !important;
    border-top: 5px solid transparent !important;
    border-bottom: 5px solid transparent !important;
    border-left: 5px solid rgba(0, 0, 0, 0.75) !important;
    right: -5px !important;
    box-shadow: none !important;
    transform: none !important;
    pointer-events: none !important;
  }

  .content {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }
}

.no-icon-pin {
  pointer-events: none !important;
}

.test-result-value {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;

  &.correct {
    color: #5B8C00;
  }

  &.incorrect {
    color: #CF1322;
  }
}

.test-result-image {
  height: 152px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
    box-shadow: 0px 0px 2px 0 rgba(34, 60, 80, 0.8);
  }
}

.locale-uk-UA {
  .country-info {
    > div {
      &:first-child {
        flex: 0 0 230px;
      }
    }
  }
}