.ant-popover {
  user-select: none !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-popover-inner {
  border-radius: 4px;
}

.ant-popover-title {
  font-size: 18px;
  padding: 14px 28px 11px;
}

.ant-checkbox-checked::after {
  display: none;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-drawer {
  top: 68px;
  height: calc(100% - 68px);

  .ant-drawer-title {
    font-weight: 550;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-drawer-body {
    overflow-x: hidden;
  }
}

.ant-pagination-item {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.anticon-close {
  width: 12.72px;
  height: 13.1px;
  color: #8c8c8c;
}

.ant-modal {
  padding-bottom: 0;
}

.ant-modal-header {
  background: transparent;
  border: 0;
  padding: 24px;
}

.ant-modal-body {
  padding: 0 24px 16px 24px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    display: none;
  }

  &::after {
    @include required-mark;
  }
}

label.ant-form-item-required {
  line-height: 20px;
}

.ant-modal-footer .ant-btn {
  width: 90px;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  outline: none;
}

.ant-btn:not(.ant-btn-lg) {
  padding: 8px 20px;
  border-radius: 30px;
  border: 1px solid #D9D9D9;
}

.ant-btn-primary {
  color: #fff;
  background: #096DD9;
  border-color: #096DD9;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #fff;
  background: #096DD9;
  border-color: #096DD9;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #262626;
  font-weight: 600;
  background-color: #fffcf0 !important;
}

.ant-checkbox-wrapper {
  line-height: 22px;
}

.ant-tag {
  border-radius: 4px !important;
}

.ant-tag-red {
  color: #a8071a;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  box-sizing: border-box;
  border-radius: 2px;
}

.ant-tag-green {
  color: #135200;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  box-sizing: border-box;
  border-radius: 2px;
}

.ant-form-item-control-input-content {
  line-height: 22px;
}

.ant-table-column-sorters {
  font-weight: 550;
}

.ant-tabs-nav-list {
  font-weight: 550;
  line-height: 22px;
}

.ant-drawer-close {
  display: flex;
  align-items: center;
  height: 100%;
}
