@mixin required-mark {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

@mixin delay($rule, $number, $value) {
  @for $i from 1 to ($number + 1) {
    &:nth-child(#{$i}) {
      -webkit-#{$rule}-delay: (#{$i*$value});
      #{$rule}-delay: (#{$i*$value});
    }
  }
}

@mixin fixed-height($value) {
  height: $value;
  min-height: $value;
  max-height: $value;
}

@mixin fixed-width($value) {
  width: $value;
  min-width: $value;
  max-width: $value;
}
