html,
body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 14px;
  //font-family: 'Mark', sans-serif;
  //font-family: Ping LCG, Mark, Fira Sans, Lato, Roboto, sans-serif;
  font-family: Open Sans, Mark, Fira Sans, Lato, Roboto, sans-serif;
  font-weight: normal;
  font-style: normal;
  //color: rgba(0, 0, 0, 0.85);
  color: #262626;
  user-select: none !important;
}

body {
  //min-width: 768px;
  //min-height: 600px;
  position: absolute;
}

.mobile-stub {
  color: wheat;
  background-color: rgba(9, 9, 9, 1);
  position: absolute;
  z-index: 10;
  width: 200px;
  top: 50%;
  left: 50%;
  text-align: center;
  margin-top: -30px;
  margin-left: -100px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}

a.link {
  color: #096DD9 !important;
}

img {
  max-width: 100%;
}

h2,
h3,
h4 {
  font-weight: 550;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.left.required label {
  &::after {
    display: none;
  }

  &::before {
    @include required-mark;
    display: inline-block;
  }
}

.touch {
  .header:not(.login) {
    .user-menu {
      .custom-avatar .ant-avatar-string {
        margin-top: -1px;
      }
    }
  }

  &.landscape {
    @media only screen and (max-height: 400px) {
      /*.color-legend, .color-legend-text {
        display: none;
      }*/

      .login-form {
        h1 {
          transform: translateY(calc(-50% - 170px));
        }
      }
    }
  }

  @media only screen and (max-height: 767px) {
    .login-form {
      h1 {
        position: absolute;
        top: 50%;
        transform: translateY(calc(-50% - 213px));
        text-align: center;
      }

      #normal_login {
        position: absolute;
        top: 50%;
        margin-top: 48px;
        transform: translateY(-50%);
        padding: 0 20px;

        .login-error-message {
          width: calc(100% - 40px);
        }

        .ant-form-item {
          margin-bottom: 16px !important;
        }
      }
    }
  }

  &.portrait {
    .dashboard-body {

      .details-row:not(.full-screen) {
        flex: 0 0 722px;
      }
    }
  }
}

@media only screen and (max-width: 828px) {
  .dashboard-header {
    .filter {
      .left-group {
        .ant-radio-group {
          display: flex;

          .ant-radio-button-wrapper {
            flex: 1;
          }
        }
      }

      .right-group {
        width: 100%;

        > div {
          width: calc(100% + 8px);

          > div {
            &:not(.collapsible) {
              flex-shrink: 0;
              flex-grow: 1;
            }

            margin-right: 8px;
            margin-bottom: 8px;

            &.collapsed {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {

  .ui.popup {
    max-width: 45vmin !important;

    .content-row-caption {
      flex: 0 0 92px !important;
    }
  }

  .header:not(.login) {
    .ant-layout-header > div:first-child {
      width: 300px;
    }

    .menu-button {
      min-width: 52px;
      width: 52px;
      max-width: 52px;
    }

    .company-logo,
    .user-name {
      display: none;
    }

    .user-menu {
      min-width: auto !important;
      padding: 0 8px !important;
    }

    .application-name {
      //min-width: 150px
      > span:first-child {
        line-height: 12px;
      }
    }
  }

  .dashboard-header, .dashboard-body {
    padding: 16px 16px 0 16px !important;
  }

  .dashboard-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;

    .color-legend {
      padding: 8px 16px !important;
      height: 34px;

      .color-legend-color {
        height: 18px;
      }
    }

    .color-legend-text {
      width: 100%;
      padding: 8px 16px !important;
      height: 34px;
      margin-top: -34px;

      .color-legend-color {
        display: none;
      }

      .color-legend-color:nth-child(1),
      .color-legend-color:nth-child(3),
      .color-legend-color:nth-child(5),
      .color-legend-color:nth-child(8),
      .color-legend-color:nth-child(10) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .color-legend-color:nth-child(5) {
        > span {
          margin-right: -4px;
        }
      }

      .color-legend-color:nth-child(8) {
        > span {
          margin-right: -9px;
        }
      }

      .color-legend-color:nth-child(3) {
        > span {
          margin-right: 1px;
        }
      }
    }

    .dashboard, .dashboard .dashboard-body {
      overflow-x: hidden;
      overflow-y: auto;
    }

    .portrait {
      .dashboard .dashboard-body {
        .greeting-text {
          padding: 40px 40px 40px 48px;

          .country-info {
            flex-direction: column;

            & > div:first-child {
              flex: 1;
            }

            & > div:nth-child(2) {
              flex: 1;
            }
          }

          .greetings {
            white-space: initial;
          }
        }

        .geo-root .map-wrapper {
          min-height: calc(70vw - 80px);
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .buttons {
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 24px;

          .ant-btn {
            margin-top: 8px;
          }
        }

        .country-info {
          flex-wrap: wrap;
        }
      }
    }
  }
}