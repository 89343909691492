.no-data-screen {
  margin-top: -48px;
}

.no-data-available-message {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
  font-size: 18px;
}

.portfolio-dashboard-header {
  font-weight: 550;
  font-size: 24px;
  width: 700px;
}

.dashboard {
  background-color: rgba(9,9,9,1);
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  .dashboard-header {
    background-color: rgba(9,9,9,1);

    &-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
    }

    .filter {
      margin-bottom: 0px !important;
      margin-right: -8px;

      > div {
        margin-bottom: 8px;
        margin-right: 8px !important;
      }
    }

    .right-group {
      margin-bottom: 8px !important;

      > div {
        margin-right: -8px;
        margin-bottom: -8px;

        > div {
          flex-shrink: 0;
          flex-grow: 1;
          margin-right: 8px;
          margin-bottom: 8px;

          &.collapsed {
            margin: 0 !important;
          }
        }
      }
    }

    .left-group, .left-group > div {
      flex-shrink: 0;
      flex-grow: 1;
    }

    .back {
      //padding-top: 6px;
      margin-top: -8px;
      min-height: 50px;
      display: flex;
      align-items: center;

      .anticon {
        font-size: 16px;
      }

      .country-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        max-width: calc(100% - 8px);

        img {
          box-shadow: 0px 0px 2px 0 rgba(34, 60, 80, 0.2);
          border-radius: 2px;
        }
      }

      .country-info {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        padding-left: 14px;
        padding-top: 3px;
        min-width: 90px;
      }
    }
  }

  .dashboard-body {
    position: relative;
    overflow: hidden;

    .details .details-row:not(.full-screen) {
      margin-right: -8px;
      margin-bottom: -8px;

      > div {
        min-width: 50vmin !important;
        margin-right: 8px;
        margin-bottom: 8px;
        overflow: hidden;
      }
    }
  }

  .chart-wrapper {
    //flex: 1 0 1px;
    //overflow: auto;
    min-height: 300px;
  }
}
