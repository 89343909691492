/*========================fade=========================*/
.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-out;
}

/*========================slide=========================*/
.slide-enter {
    transform: translateX(100%);
}

.slide-enter-active {
    transform: translateX(0);
    transition: all 0.3s ease-out;
}

.slide-enter-done {
    transform: none;
}

.slide-exit {
    transform: translateX(0);
}

.slide-exit-active {
    transform: translateX(100%);
    transition: all 0.3s ease-out;
}

/*========================fade-slide-up=========================*/
.fade-slide-up-enter {
    transform: translateY(10px);
    opacity: 0;
}

.fade-slide-up-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.4s ease-out;
}

.fade-slide-up-enter-done {
    opacity: 1;
}

.fade-slide-up-exit {
    transform: translateY(0);
    opacity: 1;
}

.fade-slide-up-exit-active {
    transform: translateY(10px);
    transition: all 0.4s ease-out;
    opacity: 0;
}
