.page-break {
  break-after: page;
  page-break-after: always;
}

.unbreak {
  page-break-inside: avoid !important;
}

// position: absolute   make impossible "page-break-after:always" in Chrome (and puppeteer engine)
// FLEXBOX              make impossible "page-break-after:always" in Chrome (and puppeteer engine)
// overflow: hidden     make impossible "page-break-after:always" in Chrome (and puppeteer engine)
.display-table {
  display: table;
  text-align: center;
}

// doesn't work well
//@media print {
//  .flex {
//    display: block !important;
//  }
//}

body.pdf-report-body {
  min-width: 1100px !important;

  div {
    pointer-events: none;
  }

  overflow: initial;
  position: relative;

  #root > div {
    position: relative;
    display: table;
    width: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 30px;

    > div {
      position: relative;
      display: table;
      width: 100%;
      text-align: center;
    }
  }

  .ant-layout-header {
    display: none;
  }

  .pdf-report {
    width: 100%;
    position: relative;
    display: table;
    text-align: center;

    > div {
      background: white !important;
    }

    .report-header {
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //border-bottom: 3px solid #F4F4F4;
    }

    .details {
      width: 100%;

      .full-screen-link {
        display: none;
      }

      .treemap .node {
        text-align: left;
        font-size: 18px !important;
      }

      .details-row {
        display: flex;
        justify-content: center;

        > div {
          min-width: 1024px;
          width: 1024px;
          max-width: 1024px;

          &:first-child {
            min-width: 900px;
            width: 900px;
            max-width: 900px;

            min-height: 650px;
            height: 650px;
            max-height: 650px;
          }

          &:nth-child(2) {
            min-height: 600px;
            margin-bottom: 10px;
          }
        }

        .donut {
          margin-top: 15px;

          .donut-wrapper {
            padding-left: 100px;
            padding-right: 100px;

            > div {
              display: contents;
            }
          }
        }
      }

      .details-table {
        min-height: 700px;

        > div > div > div:first-child > div,
        > div > div > div:first-child > div > div,
        > div > div > div:first-child > div > div > div {
          overflow: visible !important;
        }

        .details-table-cell:not(.column-header):not(.row-header) > div {
          //width: 68px;
          padding: 8px 0;
          font-size: 14px;
        }

        .details-table-cell.column-header, .empty-cell {
          height: 140px !important;
          min-height: 140px !important;
          max-height: 140px !important;
          box-shadow: none;
          background: transparent;
          width: 250px !important;
          text-align: end;
          transform: rotateZ(31deg);
          transform-origin: 28px -301px;

          > div {
            box-shadow: none;
            background: transparent;
            width: 250px !important;
            text-align: end;
          }

          .column-total-indicator {
            left: auto;
            right: 0;
          }
        }

        .column-header.details-table-cell, .row-header.details-table-cell {
          font-size: 16px !important
        }

        .details-table-cell.column-header:last-child {
          width: 60px !important;
          text-align: center;
          transform: none;

          > div > div > div {
            align-items: flex-end;
          }
        }

        .truncatedText {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
