.header {
  -webkit-perspective: 1000px;
  perspective: 1000px;
  background: #333;
  z-index: 3;

  .company-logo {
    z-index: -1;

    img {
      height: 36px;
    }
  }

  .time {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .time-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    margin-top: -7px;
  }

  .ant-avatar {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;

    .ant-avatar-string {
      line-height: 31px !important;
    }
  }

  .powered-by {
    font-size: 11px;
    padding: 1rem;
    margin-right: -0.5rem;
    cursor: pointer;
    color: #eee;
    opacity: .7;
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  .marketing-text {
    position: relative;
  }

  .language-select {
    height: 40px;
    width: 67px;
    color: #FFF;
    font-size: 9px;

    .ant-select-selector {
      height: 32px;
    }
  }

  .logout-button {
    height: 32px !important;
    padding: 0 15px 1px 15px;
    border-radius: 2px;
  }

  .header-box {
    -webkit-transform-origin: 50% 50% -30px;
    transform-origin: 50% 50% -30px;
    position: relative;
  }

  .header-back-box {
    position: absolute;
    width: 100%;
    margin-top: -76px;
  }

  .header-back {
    -webkit-animation: middleBottom 1s linear forwards;
    -moz-animation: middleBottom 1s linear forwards;
    -o-animation: middleBottom 1s linear forwards;
    animation: middleBottom 1s linear forwards;
  }

  .header-front {
    -webkit-animation: topMiddle 1s linear forwards;
    -moz-animation: topMiddle 1s linear forwards;
    -o-animation: topMiddle 1s linear forwards;
    animation: topMiddle 1s linear forwards;
  }
}

.ant-dropdown-menu-item-selected {
  color: #262626;
  font-weight: 600;
  background-color: #fffcf0!important;
}

.macintosh, .iphone {
  .header {
    .header-back {
      -webkit-animation: middleBottomMac 1s linear forwards;
      -moz-animation: middleBottomMac 1s linear forwards;
      -o-animation: middleBottomMac 1s linear forwards;
      animation: middleBottomMac 1s linear forwards;
    }

    .header-front {
      -webkit-animation: topMiddleMac 1s linear forwards;
      -moz-animation: topMiddleMac 1s linear forwards;
      -o-animation: topMiddleMac 1s linear forwards;
      animation: topMiddleMac 1s linear forwards;
    }
  }
}

@keyframes topMiddle {
  0% {
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
    z-index: 1;
  }
  50% {
    -webkit-transform: rotateX(45deg);
    -moz-transform: rotateX(45deg);
    -o-transform: rotateX(45deg);
    -ms-transform: rotateX(45deg);
    transform: rotateX(45deg);
    z-index: 1;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
    z-index: 1;
  }
}

@keyframes middleBottom {
  0% {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
    z-index: 1;
  }
  50% {
    -webkit-transform: rotateX(-45deg);
    -moz-transform: rotateX(-45deg);
    -o-transform: rotateX(-45deg);
    -ms-transform: rotateX(-45deg);
    transform: rotateX(-45deg);
    z-index: 0;
  }
  100% {
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    z-index: 0;
  }
}

@keyframes topMiddleMac {
  0% {
    -webkit-transform: rotateX(90deg) scale(0.97);
    -moz-transform: rotateX(90deg) scale(0.97);
    -o-transform: rotateX(90deg) scale(0.97);
    -ms-transform: rotateX(90deg) scale(0.97);
    transform: rotateX(90deg) scale(0.97);
    z-index: 1;
  }
  50% {
    -webkit-transform: rotateX(45deg) scale(0.97);
    -moz-transform: rotateX(45deg) scale(0.97);
    -o-transform: rotateX(45deg) scale(0.97);
    -ms-transform: rotateX(45deg) scale(0.97);
    transform: rotateX(45deg) scale(0.97);
    z-index: 1;
  }
  100% {
    -webkit-transform: rotateX(0deg) scale(0.97);
    -moz-transform: rotateX(0deg) scale(0.97);
    -o-transform: rotateX(0deg) scale(0.97);
    -ms-transform: rotateX(0deg) scale(0.97);
    transform: rotateX(0deg) scale(0.97);
    z-index: 1;
  }
}

@keyframes middleBottomMac {
  0% {
    -webkit-transform: rotateX(0deg) scale(0.97);
    -moz-transform: rotateX(0deg) scale(0.97);
    -o-transform: rotateX(0deg) scale(0.97);
    -ms-transform: rotateX(0deg) scale(0.97);
    transform: rotateX(0deg) scale(0.97);
    z-index: 1;
  }
  50% {
    -webkit-transform: rotateX(-45deg) scale(0.97);
    -moz-transform: rotateX(-45deg) scale(0.97);
    -o-transform: rotateX(-45deg) scale(0.97);
    -ms-transform: rotateX(-45deg) scale(0.97);
    transform: rotateX(-45deg) scale(0.97);
    z-index: 0;
  }
  100% {
    -webkit-transform: rotateX(-90deg) scale(0.97);
    -moz-transform: rotateX(-90deg) scale(0.97);
    -o-transform: rotateX(-90deg) scale(0.97);
    -ms-transform: rotateX(-90deg) scale(0.97);
    transform: rotateX(-90deg) scale(0.97);
    z-index: 0;
  }
}
