.explore {
  .explore-city-tooltip.leaflet-tooltip {
    font-style: normal;
    font-weight: 700;
    line-height: 8px;
    //transition: all 0.3s ease-out;
  }

  .control-button {
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease-out;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 30px;

    &:hover {
      background-color: #eee;
    }
  }

  .close-icon {
    position: absolute;
    right: 5px;
    top: 6px;
    cursor: pointer;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-top .leaflet-control {
    margin-top: 24px;
  }

  .leaflet-bottom .leaflet-control {
    margin-bottom: 24px;
  }

  .leaflet-left .leaflet-control {
    margin-left: 24px;
  }

  .leaflet-right .leaflet-control {
    margin-right: 24px;
  }

  .leaflet-control-zoom {
    display: flex;
    flex-direction: row;
    border: 0;
    margin-top: 16px !important;
    margin-bottom: 16px !important;

    a {
      border-radius: 30px !important;
      border: 1px solid #D9D9D9;
      font-weight: 400;
      transition: all 0.3s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;

      span {
        display: block;
        margin-top: 1px;
      }

      &:first-child {
        margin-right: 8px;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }

  .explore-region-tooltip {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #fff;
  }
}

.settings-menu {
  .settings-menu-content {
    padding: 16px 24px;
  }

  .secondary-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
    margin-top: 9px;
  }
}

.safari {
  .explore {
    .leaflet-control-zoom {
      a {
        span {
          display: block;
          margin-top: -2px;
        }
      }
    }
  }
}