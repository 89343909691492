.tooltipPopup {
  //transition: all 0.1s ease-out;

  &.top {
    margin-top: -88px !important;

    &:before {
      top: 92% !important;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .content-caption {
      padding-bottom: 6px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;
    }

    .content-row {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-top: 6px;
      font-size: 13px;
      height: 22px;

      .content-row-caption {
        flex: 0 0 120px;
        margin-right: 12px;
        line-height: 13px;
      }

      .content-row-sub {
        display: block;
        font-size: 10px;
        line-height: 8px;
        margin-left: 0px;
        color: #ccc;
      }
    }
  }
}
