.menu-button {
  padding: 0;
  height: 68px;
  width: 68px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}

//@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
//  .menu-button svg {
//    margin-left: 12px;
//  }
//}

.left-menu {
  .ant-drawer-header {
    padding: 16px 18px;
  }

  .ant-drawer-title {
    line-height: 32px;
    user-select: none;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-divider-horizontal {
    margin: 0 0 !important;
  }

  .ant-collapse {
    border: 0;
    background-color: #FFF;

    .ant-collapse-header {
      padding: 14px 18px !important;
      border-bottom: 0;
    }

    .ant-collapse-content {
      border-top: 0;
    }

    .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      margin-right: 14px;
    }

    .ant-collapse-item {
      border-bottom: 0;
    }
  }

  .menu-item {
    width: 100%;
    //margin-bottom: 2px;
    text-align: left;
    padding: 14px 44px !important;
    display: flex;
    align-items: center;
    line-height: 24px;
    user-select: none;
    cursor: pointer;
    position: relative;

    &.sub-menu-item {
      padding-left: 60px !important;
    }

    &:active,
    &:hover,
    &:focus {
      background: #f0f0f0;
    }

    &.active {
      //background: #f0f0f0;
      color: #096DD9 !important;
      font-weight: 700;
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        background: #096DD9;
        width: 4px;
        height: 100%;
      }
    }

    .soon {
      position: absolute;
      right: 24px;
      color: #096DD9;
      opacity: 0.4;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      //background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
    }

    &.disabled-header {
      pointer-events: none;
      border-color: #d9d9d9;
      //background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      //font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
