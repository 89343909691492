.iframe-container {
  width: 100%;

  > .ant-spin-spinning {
    position: absolute;
    //z-index: -1;
  }
}

.report-modal {
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    padding: 0 !important;
    flex: 1;
  }

  iframe {
    width: 100%;
    height: 700px;
  }
}

.create-report {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  z-index: 50;
  user-select: none;
}