$dark-2: #21262d;

.bg-main {
  background: linear-gradient(to top, $dark-2, transparent);
}

.ant-layout-header {
  padding: 0;
  color: #fff;
  height: 68px;
  box-shadow: 0px 4px 26px 0px rgba(0,0,0,0.45098039215686275);

  #menu-container {
    width: auto;
    margin-right: 0;

    .bm-overlay {
      left: 0;
      top: 0;
    }
  }
}
