.region-info {
  flex-direction: row-reverse;
  flex-wrap: wrap;

  .back {
    color: #096DD9;
    padding-bottom: 16px;
    cursor: pointer;
  }

  .region-description {
    overflow: auto;
    max-height: 700px;
    flex: 0 0 565px;
    height: calc(100vh - 164px);
  }

  .side-panel {
    margin-top: -65px;
    position: absolute;
    height: calc(100%);
    width: calc(100% - 565px);
    left: 0;

    .geo-root .map-wrapper {
      border-top-left-radius: 27px;
      border-bottom-left-radius: 27px;
    }

    > div {
      //max-width: 484px;
      width: 100%;
    }

    .right-background {
      //display: none;
      position: absolute;
      z-index: 0;
      background: #FAFAFA;
      height: calc(100%);
      width: 100% !important;
      //margin-top: -30px;
      border-top-left-radius: 27px;
      border-bottom-left-radius: 27px;
      box-shadow: inset 0 8px 14px rgba(0, 0, 0, 0.06);
    }
  }

  .ant-tabs-nav {
    margin: 0 0 24px 24px !important;
  }

  .ant-tabs-tabpane {
    overflow: auto;
  }

  .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #096DD9 !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  .ant-tabs-ink-bar {
    background: #096DD9;
  }

  .region-info-header {
    margin-top: 20px;
  }

  .region-info-value {
    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    .region-info-value-comment {
      color: #8C8C8C;
    }
  }

  .region-info-old-city-names {
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    > div {
      margin-bottom: 3px;
    }
  }

  .symbol-description {
    font-size: 14px;
    line-height: 140%;
    //padding: 0 16px;

    ul {
      list-style-type: armenian;

      li {
        margin-bottom: 8px;
        margin-left: 16px;
      }
    }

    div {
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }

  .geo-root, .geo-root, .leaflet-container {
    background: transparent;
  }

  path:not(.city-circle) {
    cursor: default !important;
  }
}