#normal_login {
  .ant-input-affix-wrapper {
    /* Neutral/1 */
    background: #fff;
    /* Neutral/5 */
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;

    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-btn {
    font-size: 16px !important;
    padding: 7px 0;
  }

  .login-error-message {
    position: absolute;
    margin-top: -46px;
    width: 100%;
  }
}
