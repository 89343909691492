.bg-login {
  background: url('./assets/images/bg-login.png') no-repeat center center fixed;
  background-size: cover;
}

.required-mark {
  &::after {
    @include required-mark;
    font-size: 18px;
    margin-left: 3px;
  }
}

.admin-table {
  .ant-table-header {
    @include fixed-height(52px);
    font-weight: 550;

    .ant-table-cell {
      @include fixed-height(52px);
      padding: 0 16px;
      font-weight: 550;
      line-height: 22px;
    }
  }

  .ant-table-body {
    overflow-y: auto !important;
    min-height: calc(100vh - 250px);

    td {
      .ant-tag {
        word-break: break-all !important;
        white-space: pre-wrap !important;
        margin-bottom: 8px;
        line-height: 20px;
      }
    }

    .ant-table-column-sort {
      background: #fff;
    }

    .ant-table-cell {
      line-height: 22px;
    }
  }

  .ant-table-cell:last-child {
    position: relative;
    padding: 0;

    .action-block {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;

      .remove-button {
        width: 100%;
      }

      .edit-button {
        width: 84px;
      }
    }
  }

  .ant-table-placeholder {
    min-height: calc(100vh - 250px);
  }

  .ant-table-expanded-row-fixed {
    min-height: calc(100vh - 251px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  circle {
    background: rgba(0, 0, 0, 0.85);
  }
}

button.custom-button {
  display: flex !important;
  align-items: center;
  height: 24px !important;
  font-size: 14px;

  &.custom-button-resize {
    padding: 2px 8px !important;
  }

  &.custom-button-noResize {
    padding: 2px 0 !important;
  }
}

span.custom-button {
  button[disabled] {
    display: flex !important;
    align-items: center;
    height: 24px !important;
    font-size: 14px;
    padding: 2px 8px !important;
  }
}

.custom-avatar {
  background-color: #fff;

  .ant-avatar-string {
    font-size: 14px;
    font-weight: 700;
    color: #000;
  }
}

.custom-checkbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }
}

.custom-select-permission {
  width: 112px;

  .ant-select-selection-item {
    display: inline-flex;
    justify-content: flex-end;
  }
}

.root {
  justify-content: center;

  .gradient {
    width: 36%;
    position: absolute;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 0;

    &.left {
      background: linear-gradient(90.18deg, rgba(255, 255, 255, 0) 0.14%, rgba(122, 156, 192, 0.7) 99.82%);
      right: 0;
    }

    &.right {
      background: linear-gradient(90.18deg, rgba(122, 156, 192, 0.7) 0.14%, rgba(255, 255, 255, 0) 99.82%);
      left: 0;
    }
  }
}

.theme-dark {
  .geo-root, .leaflet-container {
    background-color: rgba(9, 9, 9, 1);
  }
}

.portrait {
  .greeting-text {
    max-width: none;
  }

  .test {
    .geo-root {
      flex-direction: column-reverse;
    }

    .side-panel {
      width: auto;
      height: 217px;
    }

    .buttons {
      width: 150px;
      right: 24px;
    }
  }

  .symbols {
    flex-direction: column;

    .side-panel {
      flex-direction: row;
      justify-content: space-between;
      width: auto;

      .symbols-header {
        flex: 0 0 300px;
      }

      .ant-menu {
        margin-top: 0;
        margin-left: 60px;
        flex: 0 0 300px;
      }
    }
  }

  .region-info {
    height: 700px;
    flex-direction: column;

    .region-description {
      flex: 0 0 233px;
      height: auto;
    }

    .side-panel {
      margin-top: 0;
      position: absolute;
      height: calc(100% - 310px);
      width: 100%;
      bottom: 0;

      > div {
        //max-width: 484px;
        width: 100%;
      }

      .right-background {
        border-top-right-radius: 0;
        border-bottom-right-radius: 27px;
        border-bottom-left-radius: 27px;
        margin-top: -10px;
        height: calc(100% + 10px);
      }

      .geo-root .map-wrapper {
        border-bottom-left-radius: 27px;
      }
    }
  }

  &.locale-uk-UA {
    .symbols {
      .side-panel {
        .symbols-header {
          flex: 0 0 350px;
        }
      }
    }
  }
}

.leaflet-container {
  font-family: Open Sans, Mark, Fira Sans, Lato, Roboto, sans-serif;
}

.locale-en-US {
  font-family: Mark, Open Sans, Fira Sans, Lato, Roboto, sans-serif;

  .leaflet-container {
    font-family: Mark, Open Sans, Fira Sans, Lato, Roboto, sans-serif;
  }
}