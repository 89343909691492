@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Book-Italic.eot');
  src: local('Mark Book-Italic'), local('Mark-Book-Italic'),
  url('./assets/fonts/Mark/Mark-Book-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Book-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Book-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Light.eot');
  src: local('Mark Light'), local('Mark-Light'),
  url('./assets/fonts/Mark/Mark-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Light.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Medium-Italic.eot');
  src: local('Mark Medium-Italic'), local('Mark-Medium-Italic'),
  url('./assets/fonts/Mark/Mark-Medium-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Medium-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Medium-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Black-Italic.eot');
  src: local('Mark Black-Italic'), local('Mark-Black-Italic'),
  url('./assets/fonts/Mark/Mark-Black-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Black-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Black-Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Hairline.eot');
  src: local('Mark Hairline'), local('Mark-Hairline'),
  url('./assets/fonts/Mark/Mark-Hairline.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Hairline.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Hairline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Book.eot');
  src: local('Mark Book'), local('Mark-Book'),
  url('./assets/fonts/Mark/Mark-Book.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Book.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Light-Italic.eot');
  src: local('Mark Light-Italic'), local('Mark-Light-Italic'),
  url('./assets/fonts/Mark/Mark-Light-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Light-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Light-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-SmallCaps-Regular.eot');
  src: local('Mark SmallCaps-Regular'), local('Mark-SmallCaps-Regular'),
  url('./assets/fonts/Mark/Mark-SmallCaps-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-SmallCaps-Regular.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-SmallCaps-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Heavy-Italic.eot');
  src: local('Mark Heavy-Italic'), local('Mark-Heavy-Italic'),
  url('./assets/fonts/Mark/Mark-Heavy-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Heavy-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Heavy-Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Thin-Italic.eot');
  src: local('Mark Thin-Italic'), local('Mark-Thin-Italic'),
  url('./assets/fonts/Mark/Mark-Thin-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Thin-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Thin-Italic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Regular.eot');
  src: local('Mark Regular'), local('Mark-Regular'),
  url('./assets/fonts/Mark/Mark-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Regular.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Bold.eot');
  src: local('Mark Bold'), local('Mark-Bold'),
  url('./assets/fonts/Mark/Mark-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Bold.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Heavy.eot');
  src: local('Mark Heavy'), local('Mark-Heavy'),
  url('./assets/fonts/Mark/Mark-Heavy.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Heavy.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Bold-Italic.eot');
  src: local('Mark Bold-Italic'), local('Mark-Bold-Italic'),
  url('./assets/fonts/Mark/Mark-Bold-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Bold-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Bold-Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Regular-Italic.eot');
  src: local('Mark Regular Italic'), local('Mark-Regular-Italic'),
  url('./assets/fonts/Mark/Mark-Regular-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Regular-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Regular-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Medium.eot');
  src: local('Mark Medium'), local('Mark-Medium'),
  url('./assets/fonts/Mark/Mark-Medium.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Medium.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-SmallCaps-Black.eot');
  src: local('Mark SmallCaps-Black'), local('Mark-SmallCaps-Black'),
  url('./assets/fonts/Mark/Mark-SmallCaps-Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-SmallCaps-Black.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-SmallCaps-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Extralight.eot');
  src: local('Mark Extralight'), local('Mark-Extralight'),
  url('./assets/fonts/Mark/Mark-Extralight.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Extralight.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Black.eot');
  src: local('Mark Black'), local('Mark-Black'),
  url('./assets/fonts/Mark/Mark-Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Black.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Hairline-Italic.eot');
  src: local('Mark Hairline-Italic'), local('Mark-Hairline-Italic'),
  url('./assets/fonts/Mark/Mark-Hairline-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Hairline-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Hairline-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Extralight-Italic.eot');
  src: local('Mark Extralight Italic'), local('Mark-Extralight-Italic'),
  url('./assets/fonts/Mark/Mark-Extralight-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Extralight-Italic.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Extralight-Italic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Mark';
  src: url('./assets/fonts/Mark/Mark-Thin.eot');
  src: local('Mark Thin'), local('Mark-Thin'),
  url('./assets/fonts/Mark/Mark-Thin.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Thin.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'FF Mark Regular';
  src: url('./assets/fonts/Mark/Mark-Regular.eot');
  src: url('./assets/fonts/Mark/Mark-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Mark/Mark-Regular.woff') format('woff'),
  url('./assets/fonts/Mark/Mark-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABeeZee';
  src: url('./assets/fonts/ABeeZee-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABeeZeeItalic';
  src: url('./assets/fonts/ABeeZee-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Open Sans family
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/Open Sans/OpenSans-SemiboldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-BoldItalic.eot');
  src: local('Ping LCG Bold Italic'), local('PingLCG-BoldItalic'),
  url('./assets/fonts/Ping/PingLCG-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-BoldItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-Bold.eot');
  src: local('Ping LCG Bold'), local('PingLCG-Bold'),
  url('./assets/fonts/Ping/PingLCG-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-Bold.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-Bold.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-RegularItalic.eot');
  src: local('Ping LCG Regular Italic'), local('PingLCG-RegularItalic'),
  url('./assets/fonts/Ping/PingLCG-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-RegularItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-RegularItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-ThinItalic.eot');
  src: local('Ping LCG Thin Italic'), local('PingLCG-ThinItalic'),
  url('./assets/fonts/Ping/PingLCG-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-ThinItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-ThinItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-ExtraLight.eot');
  src: local('Ping LCG ExtraLight'), local('PingLCG-ExtraLight'),
  url('./assets/fonts/Ping/PingLCG-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-ExtraLight.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-ExtraLight.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-HeavyItalic.eot');
  src: local('Ping LCG Heavy Italic'), local('PingLCG-HeavyItalic'),
  url('./assets/fonts/Ping/PingLCG-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-HeavyItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-HeavyItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-Thin.eot');
  src: local('Ping LCG Thin'), local('PingLCG-Thin'),
  url('./assets/fonts/Ping/PingLCG-Thin.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-Thin.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-Thin.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-Heavy.eot');
  src: local('Ping LCG Heavy'), local('PingLCG-Heavy'),
  url('./assets/fonts/Ping/PingLCG-Heavy.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-Heavy.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-Heavy.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-Heavy.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-Regular.eot');
  src: local('Ping LCG Regular'), local('PingLCG-Regular'),
  url('./assets/fonts/Ping/PingLCG-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-Regular.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-Regular.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-MediumItalic.eot');
  src: local('Ping LCG Medium Italic'), local('PingLCG-MediumItalic'),
  url('./assets/fonts/Ping/PingLCG-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-MediumItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-MediumItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-Black.eot');
  src: local('Ping LCG Black'), local('PingLCG-Black'),
  url('./assets/fonts/Ping/PingLCG-Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-Black.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-Black.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Ping LCG Hairline';
  src: url('./assets/fonts/Ping/PingLCG-HairlineItalic.eot');
  src: local('Ping LCG Hairline Italic'), local('PingLCG-HairlineItalic'),
  url('./assets/fonts/Ping/PingLCG-HairlineItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-HairlineItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-HairlineItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-HairlineItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-Medium.eot');
  src: local('Ping LCG Medium'), local('PingLCG-Medium'),
  url('./assets/fonts/Ping/PingLCG-Medium.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-Medium.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-Medium.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-BlackItalic.eot');
  src: local('Ping LCG Black Italic'), local('PingLCG-BlackItalic'),
  url('./assets/fonts/Ping/PingLCG-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-BlackItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-BlackItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG Hairline';
  src: url('./assets/fonts/Ping/PingLCG-Hairline.eot');
  src: local('Ping LCG Hairline'), local('PingLCG-Hairline'),
  url('./assets/fonts/Ping/PingLCG-Hairline.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-Hairline.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-Hairline.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-Hairline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-ExtraLightItalic.eot');
  src: local('Ping LCG ExtraLight Italic'), local('PingLCG-ExtraLightItalic'),
  url('./assets/fonts/Ping/PingLCG-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-ExtraLightItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-ExtraLightItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-LightItalic.eot');
  src: local('Ping LCG Light Italic'), local('PingLCG-LightItalic'),
  url('./assets/fonts/Ping/PingLCG-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-LightItalic.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-LightItalic.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Ping LCG';
  src: url('./assets/fonts/Ping/PingLCG-Light.eot');
  src: local('Ping LCG Light'), local('PingLCG-Light'),
  url('./assets/fonts/Ping/PingLCG-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Ping/PingLCG-Light.woff2') format('woff2'),
  url('./assets/fonts/Ping/PingLCG-Light.woff') format('woff'),
  url('./assets/fonts/Ping/PingLCG-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}







//@font-face {
//  font-family: 'OpenSans-Bold';
//  src: url('./assets/fonts/Open Sans/OpenSans-Bold.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-BoldItalic';
//  src: url('./assets/fonts/Open Sans/OpenSans-BoldItalic.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-ExtraBold';
//  src: url('./assets/fonts/Open Sans/OpenSans-ExtraBold.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-ExtraBoldItalic';
//  src: url('./assets/fonts/Open Sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-Italic';
//  src: url('./assets/fonts/Open Sans/OpenSans-Italic.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-Light';
//  src: url('./assets/fonts/Open Sans/OpenSans-Light.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-LightItalic';
//  src: url('./assets/fonts/Open Sans/OpenSans-LightItalic.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-Regular';
//  src: url('./assets/fonts/Open Sans/OpenSans-Regular.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-Semibold';
//  src: url('./assets/fonts/Open Sans/OpenSans-Semibold.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'OpenSans-SemiboldItalic';
//  src: url('./assets/fonts/Open Sans/OpenSans-SemiboldItalic.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}