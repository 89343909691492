.test {
  .left-menu {
    width: 300px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    -webkit-box-shadow: 0px 0px 18px 4px #A9A9A9;
    box-shadow: 0px 0px 18px 4px #A9A9A9;
    background-color: #222227;

    img.flag {
      margin-top: 1px;
      width: 33px;
      box-shadow: 0px 0px 2px 0 rgba(34, 60, 80, 0.2);
      border-radius: 2px;
      cursor: pointer;

      &.selected {
        box-shadow: 0 0 6px 0 #fff;
      }
    }
  }

  .geo-root {
    flex-direction: row;
  }

  .side-panel {
    width: 350px;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);

    .test-header {
      flex-flow: wrap;
      margin-top: -8px;

      > * {
        margin-top: 8px;
      }
    }
  }

  .back {
    position: absolute;
    padding: 24px;
    left: 24px;
    bottom: 24px;
    //width: calc(100% - 48px);
    text-align: center;
    cursor: pointer;
    user-select: none;
  }

  .test-name {
    font-size: 16px;
    font-weight: 700;
  }

  .test-progress {
    padding: 8px 16px 8px 24px;
    margin-top: 24px;
    margin-left: -24px;
    background: #F5F5F5;
    border-radius: 0 40px 40px 0;
    width: fit-content;

    .correct-questions-counter {
      color: #5B8C00;
    }

    .incorrect-questions-counter {
      color: #CF1322;
    }
  }

  .question-block {
    .question {
      color: #8C8C8C;
      font-size: 12px;
    }

    .question-value {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }
  }

  .buttons {

    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);

    .additional-info {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #8C8C8C;
    }

    .stop-test {
      color: #CF1322;
      position: absolute;
      padding: 24px;
      bottom: 0;
      width: calc(100% - 48px);
      text-align: center;
      cursor: pointer;
      user-select: none;
    }

    .explore-button {
      position: absolute;
      bottom: 80px;
      background: #FFF700;
      border-radius: 30px;
      border: 0;
      font-style: normal;
      font-weight: 550;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 20px;
      min-width: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.my-custom-pin, .my-custom-pin * {
  pointer-events: none;
}

.test-result {
  .ant-modal-header {
    padding: 24px 24px 12px 24px;
  }
}

.test-result-notification {
  border-radius: 16px;
  max-width: 417px;
  width: auto;
  margin-top: 60px;
  margin-bottom: 0;

  .ant-notification-notice-message {
    margin-bottom: 4px;
  }

  .ant-notification-notice-message, .ant-notification-notice-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  img.smile {
    margin-top: -6px;
    margin-left: 2px;
  }

  .anchor {
    color: #096DD9;
    cursor: pointer;
  }
}