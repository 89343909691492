.truncatedText {
  width: 100%;
  white-space: nowrap;
  //text-overflow: ellipsis;
  //overflow: hidden;
  padding-right: 1px;
}

.truncated-name-popup {
  word-break: break-word;
  max-width: 300px;

  .ant-popover-inner-content {
    padding: 10px 13px !important;
  }
}
